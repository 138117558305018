<template>
  <div v-if="modalActivated" :class="$options.name" @click.self="closeModal">
    <slot></slot>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Modal',
  props: {
    name: { type: String },
    closeOnClickOutside: { type: Boolean, default: true },
    callback: { type: Function, default: () => {} },
  },

  computed: {
    ...mapState('ui', ['modal']),
    modalActivated() {
      return this.modal == this.name
    },
  },
  watch: {
    modalActivated(newVal) {
      if (newVal) {
        document.body.style.overflow = 'hidden'
        document.documentElement.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
        document.documentElement.style.overflow = ''
      }
    },
  },
  mounted() {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode == 27) {
        this.closeModal(this.name)
      }
    })
  },

  beforeDestroy() {
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
  },

  methods: {
    closeModal() {
      if (this.closeOnClickOutside)
        this.$store.dispatch('ui/CLOSE_MODAL', this.name)
      else this.callback()
    },
  },
}
</script>

<style lang="scss">
body {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>

<style scoped>
.Modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
