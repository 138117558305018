/* eslint-disable */
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { apolloProvider } from './vue-apollo'
import store from './store/'
import './config/datadog'

// Page layout template
import Default from '@/layouts/Default'
import NoMainNav from '@/layouts/NoMainNav'
import LeftSidebar from '@/layouts/LeftSidebar'
import VenueGroupSidebar from '@/layouts/VenueGroupSidebar'
import EmptyPage from '@/layouts/EmptyPage'
import LeftSidebarContentCenter from '@/layouts/LeftSidebarContentCenter'
import Notification from '@/layouts/Notification'
import * as Sentry from "@sentry/vue";
// // Common component
import PageLoader from '@/components/ui/preloader/PageLoader'
import LoadingApi from '@/components/ui/preloader/LoadingApi'
import Modal from '@/components/modal/Modal'
import Input from '@/components/ui/input/Input'
import Textarea from '@/components/ui/input/Textarea'
import Checkbox from '@/components/ui/input/Checkbox'
import Select from '@/components/ui/input/Select'
import RadioGroup from '@/components/ui/input/RadioGroup'
import ElementPlus from 'element-plus'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// import locale from 'element-ui/lib/locale/lang/en'
//import 'element-ui/lib/theme-chalk/index.css';
import i18n from './i18n'
import VueCropper from 'vue-cropperjs';
// import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'element-plus/dist/index.css'
import CKEditor from '@ckeditor/ckeditor5-vue';
import mitt from 'mitt';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import '../public/vuetify.min.css'
const emitter = mitt();

const datepickerOptions = {
  daysShort: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  texts: {
    cancel: 'Clear'
  },
  colors: {
    selected: '#6FEEBB',
    inRange: '#6FEEBB80',
    inRangeBorder: '#FFFFFF',
    hoveredInRange: '#6FEEBB80'
  }
}


const app = createApp(App);

window.addEventListener('error', function (event) {
  const errorMessages = ['ResizeObserver loop completed with undelivered notifications.', 'Script error.'];

  if (errorMessages.includes(event.message)) {
    event.preventDefault();
  }
});

app
  .config.errorHandler = function (err) {
    console.error('Error:', err);

  }

app.config.globalProperties.emitter = emitter;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}


app.component('template-default', Default)
app.component('template-no-main-nav', NoMainNav)
app.component('template-left-sidebar', LeftSidebar)
app.component('template-no-left-sidebar', EmptyPage)
app.component('template-left-sidebar-content-center', LeftSidebarContentCenter)
app.component('template-notification', Notification)
app.component('template-venue-group-sidebar', VenueGroupSidebar)
app.component('PageLoader', PageLoader)
app.component('LoadingApi', LoadingApi)
app.component('Modal', Modal)
app.component('Input', Input)
app.component('Textarea', Textarea)
app.component('Checkbox', Checkbox)
app.component('Select', Select)
app.component('RadioGroup', RadioGroup)
app.use(vuetify)
app.use(VueCropper)
app.use(ElementPlus)
app.use(router)
app.use(store)
app.use(apolloProvider)
// app.use(AirbnbStyleDatepicker, datepickerOptions)
app.use(CKEditor)

app.use({
  data: function () {
    return {
      user: null,
      currency: window.localStorage.currency
        ? window.localStorage.currency
        : 'gbp'
    }
  },
})
app.use(i18n)
app.use({ render: h => h(App) })

if (process.env.VUE_APP_SERVER_ENV !== 'development') {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_SERVER_ENV,
    dsn: 'https://b77537885898450881b23025fdfd0cb3@o684218.ingest.sentry.io/5831393',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  });
}

app.mount('#app')


