// initial state
const state = {
  // Modal
  modal: false,
  modalProps: {},
  modalType: null,
  // Comments
  commentsOn: false,
  commentSource: false,
  isNavOpen: false,
  isLoader: false,
  popupMyVenues: true,
}

// getters
const getters = {
  bookingId: (state) => {
    if (state.modalProps.bookingId) return state.modalProps.bookingId
  },
  modalProps: (state) => {
    return state.modalProps
  },
  popupMyVenues: (state) => {
    return state.popupMyVenues
  },
}

// actions
const actions = {
  // Modal
  SHOW_MODAL(context, payload) {
    context.commit('SHOW_MODAL', payload)
  },
  CLOSE_MODAL(context, payload) {
    context.commit('CLOSE_MODAL', payload)
  },
  SHOW_ONCLICK(context, payload) {
    context.commit('SHOW_ONCLICK', payload)
  },
  // Comments
  OPEN_COMMENTS(context, payload) {
    context.commit('OPEN_COMMENTS', payload)
  },
  CLOSE_COMMENTS(context) {
    context.commit('CLOSE_COMMENTS')
  },
  toggleNav(context, payload) {
    context.commit('toggleNav', payload)
  },
  CLOSE_POPUP_MY_VENUES(context) {
    context.commit('CLOSE_POPUP_MY_VENUES')
  },
  OPEN_POPUP_MY_VENUES(context) {
    context.commit('OPEN_POPUP_MY_VENUES')
  },
}

// mutations
const mutations = {
  // Modal
  SHOW_MODAL(state, payload) {
    state.modal = payload.name
    state.modalProps = payload.modalProps
    if (payload.modalType) state.modalType = payload.modalType
    // document.body.style.position = 'fixed'
  },
  CLOSE_MODAL(state) {
    document.body.style.position = 'unset'
    state.modal = false
    state.modalProps = false
  },
  SHOW_ONCLICK(state, payload) {
    state.isLoader = payload
  },
  resetCompanyId(state, payload) {
    state.modalProps.companyId = payload
  },
  // Comments
  OPEN_COMMENTS(state, payload) {
    state.commentsOn = true
    state.commentSource = payload
  },
  CLOSE_COMMENTS(state) {
    state.commentsOn = false
  },
  toggleNav(state, payload) {
    state.isNavOpen = payload ? payload.isOpen : !state.isNavOpen
  },
  CLOSE_POPUP_MY_VENUES(state) {
    state.popupMyVenues = false
  },
  OPEN_POPUP_MY_VENUES(state) {
    state.popupMyVenues = true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
