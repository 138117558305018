import { initialCampaignData } from './campaign.state'

// mutations
export default {
  // CAMPAIGN DASHBOARD
  ASSIGN_CAMPAIGN(state, payload) {
    Object.assign(state, payload)
  },
  STORE_CAMPAIGNS(state, payload) {
    state.campaigns = payload
  },
  getCampaignsSuccess(state, payload) {
    state.campaignsList = {
      count: payload.getCompanyCampaigns.count,
      items: [...payload.getCompanyCampaigns.items],
    }
  },
  getCampaignsError(state, payload) {
    state.errorCampaign = payload
  },
  STORE_STATUS(state, payload) {
    state.status = payload
  },

  // CAMPAIGN CREATION
  RESET_CAMPAIGN_DATA(state) {
    Object.assign(state, initialCampaignData)
    state.campaignDetail = {}
  },
  INIT_CAMPAIGN_CREATION(state, payload) {
    state.campaignDetail = {}
    state.id = payload._id
    state.brandId = payload.brandType.id
    state.brandLogo = payload.brandType.logo
    state.brandType = {
      logo: payload.brandType.logo,
      name: payload.brandType.name,
      _id: payload.brandType.id,
      checked: !!payload.brandType,
    }
    state.stakeHolders = payload.profile.stakeHolders
    state.tasks = payload.profile.tasks
    state.slug = payload.slug
    state.limitBooking = {
      max: 0,
      available: 0,
      status: 'active',
    }
    state.leadTime = 0
  },
  INIT_CAMPAIGN_EDITION(state, payload) {
    state.isRecommendedVenues =  payload?.profile?.isRecommendedVenues
    state.campaignDetail = payload
    state.exclusiveOffer = payload.exclusiveOffer
    if (payload.brandType && payload.brandType._id) {
      state.brandId = payload.brandType.id
      state.brandLogo = payload.brandType.logo
      state.brandType = {
        logo: payload.brandType.logo,
        name: payload.brandType.name,
        _id: payload.brandType.id,
        checked: !!payload.brandType,
        leadServes: payload.brandType.leadServes,
      }
    }
    state.id = payload._id
    state.slug = payload.slug
    // Overview
    state.name = payload.name
    state.experience_name = payload.experience_name
    state.description = payload.profile.description
    state.videoURL = payload.videos
    state.bookingDuration = payload.profile.bookingDuration || 0
    // campaignType = payload.profile.campaignType
    state.choiceSpecifyDateType = payload.choiceSpecifyDateType
    state.durationEta = payload.durationEta
    state.startDate = payload.startDate
    state.endDate = payload.endDate
    state.cutOffDate = payload.cutOffDate
    state.leadTime = payload.leadTime
    state.eventDuration = payload.profile.eventDuration
    state.identifier_productCategories =
      payload.profile.identifiers.productCategories[0]
    state.identifier_eventTypes = payload.profile.identifiers.eventTypes
    state.images = payload.profile.images
    // Assets, stocks & venue
    state.staffs = payload.profile.staffTypes
    state.assets = payload.profile.assets
    state.links = payload.profile.additionalLinks
    state.stocks = payload.profile.stocks
    state.requirements = payload.profile.requirements
    state.brandServes = payload.leadServes
    state.additionRequirements = payload.profile.additionRequirements
    state.assetImportRef = payload.profile.assetImportRef
    state.choiceAssetType = payload.profile.choiceAssetType || 'default'
    state.varyAssets = payload.profile.varyAssets
    state.assetEditable = payload.profile.assetEditable
    // Target venue
    state.capacity = payload.profile.targetVenue
      ? [
          payload.profile.targetVenue.capacity.min,
          payload.profile.targetVenue.capacity.max,
        ]
      : [200, 1500]
    state.targeted = payload.profile.targetVenue
      ? payload.profile.targetVenue.venueIdentifiers.included
      : []
    state.excluded = payload.profile.targetVenue
      ? payload.profile.targetVenue.venueIdentifiers.excluded
      : []
    state.targetLocation = payload.profile.targetLocation
    state.wholesaler = payload.profile.targetGroup
      ? payload.profile.targetGroup.wholesalers
      : []
    state.group = payload.profile.targetGroup
      ? payload.profile.targetGroup.customers
      : []
    state.selectedAllFields = payload.profile.selectedAllFields || {
      customers: false,
      wholesalers: false,
    }
    state.targetedStakeholders = payload.profile.targetedStakeholders || []
    state.bakTargetedStakeholders = payload.profile.targetedStakeholders || []
    state.venueSelected = payload.profile.targetedVenues || []
    state.maxBookingPerVenue = payload.profile.maxBookingPerVenue || 0
    // Sponsorship & budget
    state.sponsorship = payload.profile.sponsorship
      ? payload.profile.sponsorship.type
      : null
    state.sponsorshipType = payload.profile.sponsorship
      ? payload.profile.sponsorship.amount.type
      : null
    state.sponsorshipFund = payload.profile.sponsorship
      ? payload.profile.sponsorship.amount.fund
      : null
    state.budget = payload.profile.sponsorship
      ? payload.profile.sponsorship.budget
      : null
    state.sponsorshipPreviewPrice = payload.profile.sponsorship
      ? payload.profile.sponsorship.sponsorshipPreviewPrice
      : null
    state.paidPreviewPrice = payload.profile.sponsorship
      ? payload.profile.sponsorship.paidPreviewPrice
      : null
    // Agency & stakeholders
    state.stakeHolders = payload.profile.stakeHolders
    state.limitBooking = payload.profile.limitBooking || {
      max: 0,
      available: 0,
      status: 'active',
    }
    state.assetTurnaround = payload.profile.assetTurnaround || 24
    state.tasks = payload.profile.tasks.map((item) => {
      const { taskType, ...data } = item
      return { ...data, _id: taskType._id }
    })
    //Time slot
    state.specificTimeSlot = payload.specificTimeSlot
    state.territoryAssets = payload.profile.territoryAssets
    state.targetedTerritory = payload.profile.targetedTerritory
  },
  SAVE_TO_STORE(state, payload) {
    for (let key in payload) state[key] = payload[key]
  },

  SAVE_CURRENT_CAMPAIGN(state, payload) {
    state.campaignDetail = payload
  },

  selectBrandIdentifierSuccess(state, payload) {
    state.brandType = payload
    state.brandServes = []
  },
  activeRepTask(state) {
    const repTask = ['staff-on-location', 'live-time']
    state.tasks.forEach((task) => {
      if (repTask.includes(task.slug)) task.isActive = true
    })
  },
  inActiveRepTask(state) {
    const repTask = ['staff-on-location', 'live-time']
    state.tasks.forEach((task) => {
      if (repTask.includes(task.slug)) task.isActive = false
    })
  },
}
