<template>
  <basket-main :is-open="isOpen" @click="(e) => close(e)">
    <basket-container @click="(e) => dummiesClick(e)">
      <header-basket>
        <main-basket> My basket </main-basket>
        <!-- Hide this for EXP-3792 (kwan) -->
        <!-- <sub-basket>
            <i class="icon-booking-approval" style="margin-left: 10px;"></i>
            <text-sub>We will hold it for an hour</text-sub>
          </sub-basket> -->
      </header-basket>
      <body-basket>
        <table-grid v-show="isItemBasket()" custom="60% 20% 20%">
          <item> ITEM </item>
          <quantity> QUANTITY </quantity>
          <price> PRICE </price>
        </table-grid>
        <table-grid
          v-for="item in myBasket"
          v-show="isItemBasket()"
          :key="item.id"
          custom="60% 20% 20%"
        >
          <item-group>
            <item-image :image="item.coverImage" />
            <item-name>
              {{ item.bookingData.campaign ? item.bookingData.campaign.name : '' }}
            </item-name>
          </item-group>
          <item-quantity>
            {{ item.quantity }}
          </item-quantity>
          <item-price v-if="item.bookingData.campaign">
            <price-tag
              :currency-locale="myCountryDetails.localeICU"
              :price="
                isSponsorshipAvailable(item.bookingData.campaign.profile) ||
                isBudgetCodeOrForwardEmailSelected(item.id)
                  ? 0
                  : getItemPrice(
                      item.bookingData.campaign.priceBreakdown,
                      item.quantity,
                      item.bookingData.campaign.allowedMultiplePurchase,
                      item.bookingData.campaign.profile.sponsorship.sponsorshipPreviewPrice,
                      item.bookingData.campaign.profile.sponsorship.paidPreviewPrice
                    )
              "
              :enable-decimal="true"
              :currency-code="item.bookingData.campaign.baseCurrency"
            />
          </item-price>
        </table-grid>
        <empty-text v-show="!isItemBasket()">Your basket is empty</empty-text>
      </body-basket>
      <footer-basket>
        <total>
          Basket Total: £{{
            getDiscount.totalDiscount
              ? getTotalPrice() - getDiscount.totalDiscount
              : getTotalPrice()
          }}
        </total>
        <edit-btn @click="gotoBasketSummary()">Edit / Checkout</edit-btn>
      </footer-basket>
    </basket-container>
  </basket-main>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import * as components from './styles'
import TableGrid from '../../../../components/ui/styled/table/grid'

import PriceTag from '../../../../components/ui/PriceTag'
import calculateBasketBreakdown from '../../../../helpers/calculateBasketBreakdown'
import calculateBreakdownPrice from '../../../../helpers/calculateBreakdownPrice'

export default {
  name: 'Basket',
  components: {
    ...components,
    TableGrid,
    PriceTag,
  },
  props: ['isOpen', 'closeBasket'],
  data() {
    return {}
  },
  computed: {
    ...mapState('account', ['myCountryDetails']),
    ...mapGetters('basket', ['myBasket', 'getDiscount']),
    ...mapGetters('app', ['currencyConvert']),
    ...mapGetters('account', ['userGroup']),
    priceBreakDown() {
      return calculateBasketBreakdown(
        this.myBasket,
        this.convertPrice,
        this.myCountryDetails.localeICU,
        this.userGroup
      )
    },
  },
  methods: {
    isItemBasket() {
      return this.myBasket.length > 0
    },
    close(e) {
      e.stopPropagation()
      this.closeBasket()
    },
    dummiesClick(e) {
      e.stopPropagation()
    },
    gotoBasketSummary() {
      this.closeBasket()
      // If user not already in "/basket" page then redirect to "/basket" page to avoid sentry error.
      if (this.$route.path !== '/basket') {
        this.$router.push('/basket')
      }
    },
    convertPrice(price, locale, base) {
      const ratio = this.currencyConvert[`${base}${locale}`] || 1
      return price * ratio
    },
    getTotalPrice() {
      return this.priceBreakDown[this.priceBreakDown.length - 1].price.toFixed(
        2
      )
    },
    getItemPrice(
      priceBreakdown,
      quantity,
      allowedMultiplePurchase,
      sponsorshipPreviewPrice,
      paidPreviewPrice
    ) {
      const getBreakdownPrice = calculateBreakdownPrice(
        priceBreakdown,
        quantity,
        allowedMultiplePurchase,
        sponsorshipPreviewPrice,
        paidPreviewPrice
      )
      return (
        getBreakdownPrice.totalPriceIncVat ||
        getBreakdownPrice.totalPaidPreviewPriceIncVat
      )
    },
    isSponsorshipAvailable(profile) {
      const { sponsorship = {} } = profile
      const { type = 'None' } = sponsorship
      return type !== 'None'
    },
    isBudgetCodeOrForwardEmailSelected(basketId) {
      const myBasket = this.myBasket
      const isBudgetCodeOrForwarEmailSelected = myBasket.some(
        (basket) =>
          basket.id === basketId &&
          (basket.hasOwnProperty('budgetCode') ||
            basket.hasOwnProperty('forwardUserId'))
      )
      return isBudgetCodeOrForwarEmailSelected
    },
  },
}
</script>
