// Initial Campaign Data
export const initialCampaignData = {
  id: false,
  slug: false,
  name: null,
  // TODO: @warning deprecated in the future use name instead // updated 9 Sep 2019
  experience_name: null,
  brandId: false,
  brandLogo: false,
  // Overview
  description: null,
  // campaignType: null,
  startDate: null,
  endDate: null,
  choiceSpecifyDateType: 'flexible',
  choiceAssetType: 'default',
  durationEta: 0,
  cutOffDate: null,
  eventDuration: null,
  bookingDuration: 0,
  identifier_productCategories: [],
  identifier_eventTypes: [],
  images: null,
  videoURL: [],
  brandType: false,

  // Assets, stocks & venue
  staffs: [],
  assets: [],
  varyAssets: [],
  stocks: [],
  links: [],
  requirements: [],
  additionRequirements: [],
  brandServes: [],
  assetImportRef: null,
  assetEditable: null,
  // Target venue
  capacity: [0, 2000],
  targeted: [],
  group: [],
  targetedStakeholders: [],
  targetedTerritory: [],
  bakTargetedStakeholders: [],
  venueSelected: [],
  wholesaler: [],
  selectedAllFields: {
    cities: false,
    customers: false,
    postcodes: false,
    wholesalers: false
  },
  targetLocation: {
    cities: [],
    postcodes: []
  },
  // Sponsorship & budget
  sponsorship: 'Full',
  sponsorshipFund: 100,
  sponsorshipType: 'percentage',
  budget: 0,
  sponsorshipPreviewPrice: null,
  paidPreviewPrice: null,
  // Agency & stakeholders
  stakeHolders: [],
  tasks: [],
  exclusiveOffer: false,
  errorCampaign: false,
  assetTurnaround: 24,
  // Time slot
  specificTimeSlot: {},
  limitBooking: {
    max: 0,
    available: 0,
    status: 'active'
  },
  maxBookingPerVenue: 0,
  leadTime: 0,
  fileName: '',
  territoryAssets: [],
  isRecommendedVenues: false
}

// initial state
export default {
  //////////////////////
  // CAMPAIGN DASHBOARD
  //////////////////////
  campaigns: [],
  campaignsList: [],
  status: 'all',

  //////////////////////
  // CAMPAIGN CREATION
  //////////////////////
  ...initialCampaignData,
  campaignDetail: {},

  // Others
  uploadCsvData: {
    targetedVenues: [],
    targetedStakeholders: [],
    assets: []
  }
}
