const Cookies = require('js-cookie')
const KEY_USER_GROUP = 'userGroup'
const KEY_USER_ID = 'userId'
const KEY_USER_ROLE = 'userRole'
const KEY_ACCESS_TOKEN = 'accessToken'
const KEY_REFRESH_TOKEN = 'refreshToken'
const KEY_BEFORE_PATH = 'beforePath'
const KEY_BASKET = 'userBasket'
const KEY_VUEX = 'vuex'
const KEY_VERSION = 'version'
const KEY_RELEASE = 'release'

export const getUserGroup = () => localStorage.getItem(KEY_USER_GROUP)
export const setUserGroup = (value) =>
  localStorage.setItem(KEY_USER_GROUP, value)

export const getUserId = () => localStorage.getItem(KEY_USER_ID)
export const setUserId = (value) => localStorage.setItem(KEY_USER_ID, value)

export const getUserRole = () => localStorage.getItem(KEY_USER_ROLE)
export const setUserRole = (value) => localStorage.setItem(KEY_USER_ROLE, value)

export const getAccessToken = () => localStorage.getItem(KEY_ACCESS_TOKEN)
export const setAccessToken = (value) =>
  localStorage.setItem(KEY_ACCESS_TOKEN, value)

export const getRefreshToken = () => localStorage.getItem(KEY_REFRESH_TOKEN)
export const setRefreshToken = (value) =>
  localStorage.setItem(KEY_REFRESH_TOKEN, value)

export const getBeforePath = () => Cookies.get(KEY_BEFORE_PATH)
export const setBeforePath = (value) => {
  const date = new Date()
  const tenMinutes = date.getTime() + 10 * 1000 // 10 mins
  Cookies.set(KEY_BEFORE_PATH, value, {
    expires: tenMinutes,
  })
}
export const getVUEX = () => localStorage.getItem(KEY_VUEX)

export const clearUserData = () => {
  localStorage.removeItem(KEY_REFRESH_TOKEN)
  localStorage.removeItem(KEY_USER_GROUP)
  localStorage.removeItem(KEY_USER_ID)
  localStorage.removeItem(KEY_USER_ROLE)
  localStorage.removeItem(KEY_ACCESS_TOKEN)
  localStorage.removeItem(KEY_VERSION)
}

export const clearBeforePath = () => {
  Cookies.remove(KEY_BEFORE_PATH)
}

export const getUserBasket = () => [
  JSON.parse(localStorage.getItem(KEY_BASKET)),
]
export const setUserBasket = () =>
  localStorage.setItem(KEY_BASKET, null)

export const clearUserBasket = () => {
  localStorage.removeItem(KEY_BASKET)
}

export const getVersion = () => localStorage.getItem(KEY_VERSION)
export const setVersion = (value) => localStorage.setItem(KEY_VERSION, value)
export const getRelease = () => localStorage.getItem(KEY_RELEASE)
export const setRelease = (value) => localStorage.setItem(KEY_RELEASE, value)