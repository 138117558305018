export default {
  getAssetListSuccess: (state, payload) => {
    state.assetList = { ...payload, isLoaded: true }
    state.error = null
  },
  getStaffTypesListSuccess: (state, payload) => {
    state.staffTypesList = { ...payload, isLoaded: true }
    state.error = null
  },
  getStockListSuccess: (state, payload) => {
    state.stockList = { ...payload, isLoaded: true }
    state.error = null
  },
  getStaffTypesListError: (state, payload) => (state.error = payload),
  getAssetListError: (state, payload) => (state.error = payload),
  getEventTypeListSuccess: (state, payload) => {
    state.eventTypeList = payload.eventTypeMany
    state.eventTypeList.map((item) => (item.checked = false))
    state.error = null
  },
  setTmpEventTypeList: (state, payload) => {
    state.tmpEventTypeList = payload
  },
  setTmpBrandList: (state, payload) => {
    state.tmpBrandList = payload
  },
  getBrandListSuccess: (state, payload) => {
    state.brandList = payload.getBrandActiveCampaign
    state.brandList.map((item) => (item.checked = false))
    state.error = null
  },
  getCampaignsOnMarketplaceSuccess: (state, payload) => {
    state.campaignsOnMarketplaceList = {
      items: [...payload.items],
      count: payload.count,
      isLoaded: true,
    }
    state.error = null
  },
  campaignOfferGroupSuccess: (state, payload) => {
    state.campaignOfferGroup = { ...payload, isLoaded: true }
    state.error = null
  },
  taskTypeManySuccess: (state, payload) => {
    state.tasksList = { ...payload, isLoaded: true }
    state.error = null
  },
  // set error
  campaignOfferGroupError: (state, error) => {
    state.error = error
  },
  getCampaignsOnMarketplaceError: (state, payload) => {
    state.campaignsOnMarketplaceList = {
      ...state.campaignsOnMarketplaceList,
      error: payload,
    }
    state.error = payload
  },
  getEventTypeListError: (state, payload) => (state.error = payload),
  getBrandListError: (state, payload) => (state.error = payload),
  taskTypeManyError: (state, payload) => (state.error = payload),
  // set state
  setStateStock: (state, payload) => {
    state.stockSelected = payload
  },
  setStateAsset: (state, payload) => {
    state.assetSelected = payload
  },
  setStateStaff: (state, payload) => {
    state.staffSelected = payload
  },
  setStartDateFilter: (state, payload) => {
    state.startDateFilter = payload
  },
  setEndDateFilter: (state, payload) => {
    state.endDateFilter = payload
  },
  setVaryAsset(state, payload) {
    state.varyAssets = payload
  },
  setRescheduleAsset(state, payload) {
    state.rescheduleAssets = payload
  },
  setVerifyInventory(state, payload) {
    state.verifyInventory = payload
  },
  setDateFilter: (state) => {
    state.dateFilter = {
      startDate: state.startDateFilter,
      endDate: state.endDateFilter,
    }
  },
  setStringDate: (state, payload) => (state.stringDate = payload),
  setTypeEventFilter: (state, payload) => {
    state.eventTypeFilter = payload
  },
  setBrandFilter: (state, payload) => {
    state.brandFilter = payload
  },
  setVenueIds: (state, payload) => {
    state.venueIds = payload
  },
  clearFilterData: (state, payload) => {
    state.venueIds = []
    if (payload === 'all') {
      state.startDateFilter = ''
      state.endDateFilter = ''
      state.dateFilter = {}
      state.stringDate = ''
      state.eventTypeFilter = []
      state.eventTypeList = []
      state.brandFilter = []
      state.brandList = []
      state.stockSelected = []
      state.tmpEventTypeList = {}
      state.tmpBrandList = {}
    } else if (payload === 'dates') {
      state.startDateFilter = ''
      state.endDateFilter = ''
      state.dateFilter = {}
      state.stringDate = ''
    }
  },
  setStakeholders: (state, payload) => {
    state.stakeholders = payload
  },
  setHandleError: (state, payload) => {
    state.handleError = payload
  },
}
