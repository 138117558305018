function isSponsorshipAvailable(profile) {
  const { sponsorship = {} } = profile
  const { type = 'None' } = sponsorship
  return type !== 'None'
}

function isSponsorshipTaskInactiveWithPOCode(tasks = []) {
  return tasks.some(
    ({ slug, isActive, assignPOCode = {} }) =>
      slug === 'sponsorship-requested' && !isActive && assignPOCode.code
  )
}

export default function calculateBasketBreakdown(
  items,
  convertPrice,
  localeICU,
  role = ''
) {
  const fixDiscount = 0
  const totalPrice = items
    .map((item) => {
      const price = isSponsorshipAvailable(item.bookingData.campaign.profile)
        ? 0
        : item.bookingData.campaign.priceBreakdown.additionalPrice.price ||
          item.bookingData.campaign.priceBreakdown.grandTotalPaidPreview
      return convertPrice(
        price,
        localeICU,
        item.bookingData.campaign.baseCurrency
      )
    })
    .reduce((acc, cur) => acc + cur, 0)
  const pricrBreakdown = [
    {
      name: 'Total price',
      price: totalPrice,
      type: 'heading',
      style: role === 'brand' ? 'last' : null,
    },
  ]
  // TODO: prepare for A&P wallet
  // pricrBreakdown.push({
  //   name: '- Diageo Rewards Saving',
  //   price: fixDiscount,
  //   type: 'subhead',
  //   style: 'last'
  // })
  let subTotal = items
    .map((item) => {
      const price =
        isSponsorshipAvailable(item.bookingData.campaign.profile) ||
        isSponsorshipTaskInactiveWithPOCode(item.bookingData.campaign.profile.tasks) ||
        item.forwardUserId ||
        item.budgetCode
          ? 0
          : item.bookingData.campaign.priceBreakdown.additionalPrice.price ||
            item.bookingData.campaign.priceBreakdown.grandTotalPaidPreview
      return convertPrice(
        price,
        localeICU,
        item.bookingData.campaign.baseCurrency
      )
    })
    .reduce((acc, cur) => acc + cur, 0)
  subTotal = subTotal + fixDiscount

  pricrBreakdown.push({
    name: 'Remainder to Pay ',
    price: subTotal,
    type: 'heading',
  })

  return pricrBreakdown
}
