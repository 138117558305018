<template>
  <div class="Page-Loader -modal" :class="{ background: backgroundActive }">
    <div class="Page-Loader_wrapper">
      <font-awesome-icon v-show="!hideSpinner" icon="spinner" size="4x" pulse />
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSpinner);

export default {
  name: 'LoadingApi',
  components: { FontAwesomeIcon },
  props: {
    detailLoader1: {
      type: String,
      default: '',
    },
    detailLoader2: {
      type: String,
      default: '',
    },
    hideSpinner: {
      type: Boolean,
      default: false,
    },
    backgroundActive: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.Page-Loader.-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999; 

}

.Page-Loader_wrapper {
  color: $primary;
  display: flex;
  z-index: 100000;
  align-items: center;
  flex-direction: column;
}
</style>